import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavigationBar from './NavigationBar';

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [planDetails, setPlanDetails] = useState({
    capital_invested: 0,
    current_balance: 0,
    interest_generated: 0,
    days_in_plan: 0,
  });
  const [userDetails, setUserDetails] = useState({ wallet: '', email: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationType, setConfirmationType] = useState('');
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    fetchUsers();
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/dashboard-data`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      setUserName(data.userName || '');
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/users`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      if (!Array.isArray(data)) {
        throw new Error('La respuesta del servidor no es un array.');
      }

      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Error al cargar los usuarios.');
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setUserDetails({ wallet: user.wallet_address, email: user.email });
    setIsUserModalOpen(true);
  };

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setPlanDetails({
      capital_invested: plan.capital_invested,
      current_balance: plan.current_balance,
      interest_generated: plan.interest_generated,
      days_in_plan: plan.days_in_plan,
    });
    setIsModalOpen(true);
  };

  const handlePlanInputChange = (e) => {
    const { name, value } = e.target;
    setPlanDetails({ ...planDetails, [name]: value });
  };

  const handleUserInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  // Abrir el modal de confirmación y ocultar el modal actual
  const handleConfirmUpdate = (type) => {
    setConfirmationType(type);
    setIsConfirmationModalOpen(true);
    setIsModalOpen(false); // Ocultar modal de plan
    setIsUserModalOpen(false); // Ocultar modal de usuario
  };

  // Proceder con la actualización después de la confirmación
  const proceedWithUpdate = async () => {
    if (confirmationType === 'plan') {
      await updatePlan();
    } else if (confirmationType === 'user') {
      await updateUser();
    }
    setIsConfirmationModalOpen(false);
  };

  const updatePlan = async () => {
    try {
      if (!selectedUser || !selectedPlan || !selectedPlan.plan_id) {
        throw new Error('No se ha seleccionado un usuario o plan.');
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/update-plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          user_id: selectedUser.user_id,
          plan_id: selectedPlan.plan_id,
          ...planDetails,
        }),
      });

      if (response.ok) {
        toast.success('Plan actualizado correctamente.');
        setIsModalOpen(false);
        fetchUsers(); // Refrescar lista de usuarios
      } else {
        toast.error('Error al actualizar el plan.');
      }
    } catch (error) {
      console.error('Error updating plan:', error);
      toast.error('Error al actualizar el plan.');
    }
  };

  const updateUser = async () => {
    try {
      if (!selectedUser) {
        toast.error('No se ha seleccionado un usuario.');
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/update-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          user_id: selectedUser.user_id,
          ...userDetails,
        }),
      });

      if (response.ok) {
        toast.success('Usuario actualizado correctamente.');
        setIsUserModalOpen(false);
        fetchUsers(); // Refrescar lista de usuarios
      } else {
        toast.error('Error al actualizar la información del usuario.');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Error al actualizar la información del usuario.');
    }
  };

  // Mostrar modal de confirmación
  const renderConfirmationModal = () => {
    return (
      isConfirmationModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-800 p-8 rounded-lg max-w-lg w-full">
            <h3 className="text-2xl font-bold text-teal-400">Confirmar Actualización</h3>
            {confirmationType === 'plan' ? (
              <>
                <p className="text-white mt-4">¿Estás seguro de que deseas actualizar el plan?</p>
                <div className="mt-4">
                  <h4 className="text-teal-400">Datos Actuales:</h4>
                  <p className="text-white">Capital Invertido: {selectedPlan.capital_invested}</p>
                  <p className="text-white">Saldo Actual: {selectedPlan.current_balance}</p>
                  <p className="text-white">Intereses Generados: {selectedPlan.interest_generated}</p>
                  <p className="text-white">Días en el Plan: {selectedPlan.days_in_plan}</p>
                </div>
                <div className="mt-4">
                  <h4 className="text-teal-400">Nuevos Datos:</h4>
                  <p className="text-white">Capital Invertido: {planDetails.capital_invested}</p>
                  <p className="text-white">Saldo Actual: {planDetails.current_balance}</p>
                  <p className="text-white">Intereses Generados: {planDetails.interest_generated}</p>
                  <p className="text-white">Días en el Plan: {planDetails.days_in_plan}</p>
                </div>
              </>
            ) : (
              <>
                <p className="text-white mt-4">¿Estás seguro de que deseas actualizar el usuario?</p>
                <div className="mt-4">
                  <h4 className="text-teal-400">Datos Actuales:</h4>
                  <p className="text-white">Wallet: {selectedUser.wallet_address}</p>
                  <p className="text-white">Email: {selectedUser.email}</p>
                </div>
                <div className="mt-4">
                  <h4 className="text-teal-400">Nuevos Datos:</h4>
                  <p className="text-white">Wallet: {userDetails.wallet}</p>
                  <p className="text-white">Email: {userDetails.email}</p>
                </div>
              </>
            )}
            <div className="mt-6 flex justify-between">
              <button
                className="bg-red-600 px-4 py-2 rounded-md hover:bg-red-500 text-white"
                onClick={() => {
                  setIsConfirmationModalOpen(false);
                  if (confirmationType === 'plan') setIsModalOpen(true); // Reabrir modal de plan
                  if (confirmationType === 'user') setIsUserModalOpen(true); // Reabrir modal de usuario
                }}
              >
                Cancelar
              </button>
              <button
                className="bg-teal-600 px-4 py-2 rounded-md hover:bg-teal-500 text-white"
                onClick={proceedWithUpdate}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    <div
      className="min-h-screen bg-cover bg-center bg-no-repeat relative z-0"
      style={{
        backgroundImage: `url('${process.env.PUBLIC_URL}/images/fondo2.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Barra de navegación */}
      <div className="relative z-50">
        <NavigationBar userName={userName} />
      </div>

      {/* Contenido principal */}
      <div className="flex flex-col items-center justify-center p-6 relative z-10 min-h-screen">
        <h2 className="text-2xl font-bold mb-4">Gestión de Usuarios</h2>

        {loadingUsers ? (
          <p className="text-center">Cargando usuarios...</p>
        ) : users.length === 0 ? (
          <p className="text-center">No hay usuarios registrados.</p>
        ) : (
          <div className="overflow-x-auto w-full">
            <table className="min-w-full bg-gray-800 rounded-lg text-left text-sm md:text-base">
              <thead>
                <tr>
                  <th className="px-4 md:px-6 py-3">Nombre</th>
                  <th className="px-4 md:px-6 py-3">Email</th>
                  <th className="px-4 md:px-6 py-3">Rol</th>
                  <th className="px-4 md:px-6 py-3">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.user_id} className="hover:bg-gray-700 cursor-pointer">
                    <td className="px-4 md:px-6 py-3">{user.username}</td>
                    <td className="px-4 md:px-6 py-3">{user.email}</td>
                    <td className="px-4 md:px-6 py-3">{user.role}</td>
                    <td className="px-4 md:px-6 py-3">
                      <button
                        className="bg-blue-600 px-3 md:px-4 py-2 rounded-md hover:bg-blue-500 text-xs md:text-base"
                        onClick={() => handleUserClick(user)}
                      >
                        Ver Detalles
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Modal para confirmar la actualización */}
        {renderConfirmationModal()}

        {/* Modal para editar el usuario */}
        {isUserModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
            <div className="bg-gray-800 p-8 rounded-lg max-w-lg w-full">
              <div className="flex justify-between items-center">
                <h3 className="text-2xl font-bold text-teal-400">Detalles del Usuario</h3>
                <FaTimes className="text-white cursor-pointer" onClick={() => setIsUserModalOpen(false)} />
              </div>
              <div className="mt-6">
                <div className="mb-4">
                  <label className="block text-gray-400">Wallet:</label>
                  <input
                    type="text"
                    name="wallet"
                    value={userDetails.wallet}
                    onChange={handleUserInputChange}
                    className="w-full bg-gray-700 p-2 rounded-md text-white"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-400">Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={userDetails.email}
                    onChange={handleUserInputChange}
                    className="w-full bg-gray-700 p-2 rounded-md text-white"
                  />
                </div>

                <div>
                  <h4 className="mt-4 text-white">Planes del Usuario</h4>
                  {selectedUser.plans.length === 0 ? (
                    <p className="text-white mt-2">Este usuario no tiene planes.</p>
                  ) : (
                    <ul className="mt-2 space-y-2">
                      {selectedUser.plans.map((plan) => (
                        <li
                          key={plan.plan_id}
                          className={`p-2 rounded-md cursor-pointer ${
                            selectedPlan?.plan_id === plan.plan_id ? 'bg-teal-500' : 'bg-gray-700'
                          }`}
                          onClick={() => handlePlanSelect(plan)}
                        >
                          {`Plan ID: ${plan.plan_id}`}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <button
                  onClick={() => handleConfirmUpdate('user')}
                  className="w-full mt-4 px-6 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-500"
                >
                  Guardar Cambios
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Modal para editar el plan */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
            <div className="bg-gray-800 p-8 rounded-lg max-w-lg w-full">
              <div className="flex justify-between items-center">
                <h3 className="text-2xl font-bold text-teal-400">Detalles del Plan</h3>
                <FaTimes className="text-white cursor-pointer" onClick={() => setIsModalOpen(false)} />
              </div>
              <div className="mt-6 space-y-4">
                <div>
                  <label className="block text-gray-400">Capital Invertido:</label>
                  <input
                    type="number"
                    name="capital_invested"
                    value={planDetails.capital_invested}
                    onChange={handlePlanInputChange}
                    className="w-full bg-gray-700 p-2 rounded-md text-white"
                  />
                </div>
                <div>
                  <label className="block text-gray-400">Saldo Actual:</label>
                  <input
                    type="number"
                    name="current_balance"
                    value={planDetails.current_balance}
                    onChange={handlePlanInputChange}
                    className="w-full bg-gray-700 p-2 rounded-md text-white"
                  />
                </div>
                <div>
                  <label className="block text-gray-400">Intereses Generados:</label>
                  <input
                    type="number"
                    name="interest_generated"
                    value={planDetails.interest_generated}
                    onChange={handlePlanInputChange}
                    className="w-full bg-gray-700 p-2 rounded-md text-white"
                  />
                </div>
                <div>
                  <label className="block text-gray-400">Días en el Plan:</label>
                  <input
                    type="number"
                    name="days_in_plan"
                    value={planDetails.days_in_plan}
                    onChange={handlePlanInputChange}
                    className="w-full bg-gray-700 p-2 rounded-md text-white"
                  />
                </div>
                <button
                  onClick={() => handleConfirmUpdate('plan')}
                  className="w-full mt-4 px-6 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-500"
                >
                  Guardar Cambios
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <ToastContainer />
    </div>
  );
};

export default AdminUsers;
